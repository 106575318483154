<!-- eslint-disable no-debugger -->
<template>
    <div class="comp-container">
        <div class="search-view" v-show="searchViewVisible">
            <div class="patient-box">
                <div>请输入就诊人姓名</div>
                <el-input class="input-patient-name" placeholder="输入就诊人姓名" v-model="patientName" clearable></el-input>
            </div>
            <div class="key-box">
                <div>请输入查询关键字</div>
                <el-input class="input-query-key" placeholder="门诊号 或 住院号 或 手机号 或 身份证号" v-model="queryKey" clearable></el-input>
                <div class="query-key-hint">输入门诊号、住院号、手机号、身份证号任意一个</div>
            </div>
            <div class="hospital-box" v-if="!selectedHospital" @click="showHospitalList">
                <div>请选择就诊医院</div>
                <el-input placeholder="选择就诊医院" v-model="hospitalName" readonly suffix-icon="el-icon-arrow-right"></el-input>
            </div>
            <div class="search-box">
                <el-button class="btn-search" type="primary" @click="searchStudy">查找检查</el-button>
            </div>
        </div>
        <div class="query-result-view" v-if="resultViewVisible">
            <!-- <StudyListView :patientName="patientName" v-on:closeView="closeResultView" /> -->
        </div>
        <div class="popup-view-container hide" ref="popViewContainer">
            <HospitalListView v-on:closeView="closePopupView" v-on:selectHospital="onSelectHospital"/>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
import HospitalListView from './HospitalListView.vue'
import { store } from '../../utils/store'
import { MessageProxy } from '../../utils/MessageProxy'

export default {
    name: 'StudySearchView',
    components: {
        HospitalListView,
        // StudyListView
    },
    data() {
        return {
            searchViewVisible: true,
            resultViewVisible: false,
            selectedHospital: null,
            patientName: "",
            queryKey: ""
        }
    },
    computed: {
        hospitalName: function() {
            if (null == this.selectedHospital) {
                return "";
            }
            return this.selectedHospital.name;
        }
    },
    created() {
        if (store.hospital.id) {
            this.selectedHospital = store.hospital;
        } else {
            // alert('store.hospital.shortName ' + store.hospital.shortName)
            if (store.hospital.shortName) {
                MessageProxy.getHospitalInfo(store.hospital.shortName).then((result) => {
                    if (true == result.hasError) {
                        console.error(result.errorText);
                        return;
                    }
                    if (result.data != null && result.data.code != '200') {
                        console.error(result.data.message);
                        return;
                    }
                    if (result.data.data != null) {
                        store.hospital.id = result.data.data.id;
                        this.selectedHospital = store.hospital;
                    }
                })
            }
        }
        
    },
    // mounted() {
    //     console.log("$route", this.$route)
    // },
    // updated() {
    //     console.log("updated $route", this.$route)
    // },
    methods: {
        // selectHospital() {
        //     this.$message.info("选择医院");
        // },
        searchStudy() {
            if ("" === this.patientName) {
                this.$message.error("请输入就诊人姓名！");
                return;
            }
            if ("" === this.queryKey) {
                this.$message.error("请输入查询关键字！");
                return;
            }
            // if (null === this.selectedHospital) {
            //     this.$message.error("请选择就诊医院！");
            //     return;
            // }
            // this.resultViewVisible = true;
            let hospitalId = "";
            if (this.selectedHospital != null) {
                hospitalId = this.selectedHospital.id;
            }


            this.$router.push({name: 'studylist', query:{patientName: this.patientName, queryKey: this.queryKey, hospitalId: hospitalId}});
        },
        onSelectHospital(hospitalId) {
            let hospital = null;
            for (const hosp of store.hospitals) {
                if (hosp.id == hospitalId) {
                    hospital = hosp;
                    store.hospital = hosp
                }
            }
            this.selectedHospital = hospital;
            this.closePopupView();
        },
        showHospitalList() {
            
            let elPopViewContainer = this.$refs.popViewContainer;
            elPopViewContainer.classList.remove('hide');
            // this.$router.push('/hospitallist');
        },
        closePopupView() {
            let elPopViewContainer = this.$refs.popViewContainer;
            elPopViewContainer.classList.add('hide');
        },
        closeResultView() {
            this.resultViewVisible = false;
        }
    }
}
</script>

<style scoped>
    .comp-container {
        height: 100%;
    }
    .search-view,
    .hospital-view,
    .result-view {
        box-sizing: border-box;
        height: 100%;
        overflow-y: auto;
    }
    .search-view {
        padding: 10vmin 10vmin 0 10vmin;
        text-align: left;
        font-size: 4vmin;
    }
    .search-view .key-box,
    .search-view .hospital-box {
        margin-top: 6vmin;
    }
    .search-view .query-key-hint {
        font-size: 3vmin;
        margin-top: 1vmin;
        color: #999;
    }
    .search-view .search-box {
        margin-top: 20vmin;
        text-align: center;
    }
    .search-view .search-box .btn-search {
        width: 100%;
    }

    .query-result-view,
    .popup-view-container {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #f6f6f6;
        z-index: 2;
    }
    .popup-view-container.hide {
        display: none;
    }

</style>